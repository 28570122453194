import pimg1 from "../images/portfolio/12.jpg";
import pimg2 from "../images/portfolio/13.jpg";
import pimg3 from "../images/portfolio/14.jpg";
import pimg4 from "../images/portfolio/15.jpg";
import pimg7 from "../images/portfolio/16.jpg";
import pimg8 from "../images/portfolio/17.jpg";

import pSimg1 from "../images/portfolio/12.jpg";
import pSimg2 from "../images/portfolio/2.jpg";
import pSimg3 from "../images/portfolio/3.jpg";
import pSimg4 from "../images/portfolio/4.jpg";
import pSimg7 from "../images/portfolio/12.jpg";
import pSimg8 from "../images/portfolio/24.jpg";

const Projects = [
  {
    id: "1",
    title: "Jack & Rose",
    slug: "Jack-Rose",
    pimg1: pimg1,
    pSimg: pSimg1,
    date: "Feb 2019",
    location: "Austria",
  },
  {
    id: "2",
    title: "Jenifer & Harry",
    slug: "Jenifer-Harry",
    pimg1: pimg2,
    pSimg: pSimg2,
    date: "Jan 2019",
    location: "Austria",
  },
  {
    id: "3",
    title: "Miller & Aiyana",
    slug: "Miller-Aiyana",
    pimg1: pimg3,
    pSimg: pSimg3,
    date: "Mar 2020",
    location: "Austria",
  },
  {
    id: "4",
    title: "Jane & Peter",
    slug: "Jane-Peter",
    pimg1: pimg4,
    pSimg: pSimg4,
    date: "Apr 2020",
    location: "Austria",
  },
  {
    id: "5",
    title: "Lucas & Mia",
    slug: "Lucas-Mia",
    pimg1: pimg7,
    pSimg: pSimg7,
    date: "Jun 2023",
    location: "Austria",
  },
  {
    id: "6",
    title: "Michael & Caoimhe",
    slug: "James-Amelia",
    pimg1: pimg8,
    pSimg: pSimg8,
    date: "Jun 2023",
    location: "Austria",
  },
];
export default Projects;
