import React, { useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { useSaveForm } from "../../hooks/useSaveForm";
const RSVPFrom = () => {
  const { submit, loading, submitted } = useSaveForm();
  const [forms, setForms] = useState({
    name: "",
    email: "",
    date: "",
    guest: "",
    meal: "",
    reason: "",
    attend: "Yes",
  });
  const [validator] = useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );
  const changeHandler = (e) => {
    setForms({
      ...forms,
      [e.target.name]: e.target.value,
      date: new Date().toUTCString(),
    });
    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      validator.hideMessages();
      submit(forms);
      setForms({
        name: "",
        email: "",
        date: "",
        guest: "",
        meal: "",
        reason: "",
      });
    } else {
      validator.showMessages();
    }
  };

  return (
    <form
      onSubmit={(e) => submitHandler(e)}
      className="contact-validation-active"
    >
      <div className="row">
        <div className="col col-lg-12 col-12">
          <div className="form-field">
            <input
              value={forms.name}
              type="text"
              name="name"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              className="form-control"
              placeholder="Your Name"
            />
            {validator.message("name", forms.name, "required|alpha_space")}
          </div>
        </div>
        <div className="col col-lg-12 col-12">
          <div className="form-field">
            <input
              value={forms.email}
              type="email"
              name="email"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              className="form-control"
              placeholder="Your Email"
            />
            {validator.message("email", forms.email, "required|email")}
          </div>
        </div>
        <div className="col col-lg-12 col-12">
          <div className="form-field">
            <input
              value={forms.address}
              type="address"
              name="address"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              className="form-control"
              placeholder="Your Address"
            />
          </div>
        </div>
        <div className="col col-lg-12 col-12">
          <div className="form-field">
            <input
              value={forms.dietary}
              type="text"
              name="dietary"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              className="form-control"
              placeholder="Any dietary requirements?"
            />
          </div>
        </div>

        <div className="col col-lg-12 col-12">
          <div className="radio-buttons">
            <p>
              <input
                id="attend"
                type="radio"
                name="attending"
                value={"Yes"}
                onChange={changeHandler}
                checked={forms.attending === "Yes"}
              />
              <label htmlFor={"attend"}>Yes, I will be there</label>
            </p>
            <p>
              <input
                id="notAttend"
                type="radio"
                name="attending"
                value={"No"}
                onChange={changeHandler}
                checked={forms.attending === "No"}
              />
              <label htmlFor={"notAttend"}>Sorry, I can’t attend</label>
            </p>
          </div>
        </div>
        {forms.attending === "Yes" && (
          <div className="col col-lg-12 col-12">
            <div className="form-field">
              <input
                value={forms.accommodation}
                type="text"
                name="accommodation"
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
                className="form-control"
                placeholder="Will you be staying the night at Tankdardstown? "
              />
            </div>
          </div>
        )}
        <div className="col col-lg-12 col-12">
          <div className="form-field">
            <select
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              value={forms.guest}
              type="text"
              className="form-control"
              name="guest"
            >
              <option>Number Of Guests</option>
              <option>01</option>
              <option>02</option>
              <option>03</option>
              <option>04</option>
            </select>
            {validator.message("guest", forms.guest, "required")}
          </div>
        </div>
        <div className="col col-lg-12 col-12">
          <div className="form-field">
            <input
              value={forms.notes}
              type="text"
              name="reason"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              className="form-control"
              placeholder="Comments"
            />
          </div>
        </div>
      </div>
      <div className="submit-area">
        <button type="submit" className="theme-btn" disabled={loading}>
          RSVP
        </button>
      </div>
      {submitted && (
        <div class="mt-5 alert alert-success" role="alert">
          Thanks so much for RSVPing!
        </div>
      )}
    </form>
  );
};

export default RSVPFrom;
