import React from "react";
import pImg1 from "../../images/portfolio/1.png";
import pImg2 from "../../images/portfolio/2.png";
import pImg3 from "../../images/portfolio/3.png";
import pImg4 from "../../images/portfolio/4.png";
import pImg5 from "../../images/portfolio/5.jpg";
import pImg6 from "../../images/portfolio/6.jpg";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
  dots: false,
  arrows: true,
  speed: 1000,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Portfolios = [
  {
    Pimg: pImg1,
  },
  {
    Pimg: pImg2,
  },
  {
    Pimg: pImg3,
  },
  {
    Pimg: pImg4,
  },
  {
    Pimg: pImg5,
  },
  {
    Pimg: pImg6,
  },
];

const PortfolioSection = (props) => {
  const [open, setOpen] = React.useState(false);

  return (
    <section
      className={`wpo-portfolio-section section-padding ${props.ptClass}`}
      id="gallery"
    >
      <h2 className="hidden">some</h2>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="portfolio-grids gallery-container clearfix portfolio-slide">
              <Slider {...settings}>
                {Portfolios.map((image, i) => (
                  <div className="grid" key={i}>
                    <div className="img-holder" onClick={() => setOpen(true)}>
                      <img
                        src={image.Pimg}
                        alt=""
                        className="img img-responsive"
                      />
                      <div className="hover-content">
                        <i className="ti-plus"></i>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={[
          { src: pImg1 },
          { src: pImg2 },
          { src: pImg3 },
          { src: pImg4 },
          { src: pImg5 },
          { src: pImg6 },
        ]}
      />
    </section>
  );
};

export default PortfolioSection;
