import { useState } from "react";
export const useSaveForm = () => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const submit = (data) => {
    setLoading(true);
    const scriptUrl =
      "https://sheet.best/api/sheets/1320d87b-0817-4e30-a4ac-1d3d350f96de";

    fetch(scriptUrl, {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        console.log("SUCCESSFULLY SUBMITTED");
      })
      .catch((err) => console.log(err))
      .then(() => setLoading(false), setSubmitted(true));
  };

  return { submit, loading, submitted };
};

export default useSaveForm;
