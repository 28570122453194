import React from "react";
import { Slide } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import shape1 from "../../images/footer-shape-1.svg";
import shape2 from "../../images/footer-shape-2.svg";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const Footer = (props) => {
  return (
    <footer className={`wpo-site-footer ${props.footerClass}`}>
      <div className="wpo-upper-footer">
        <div className="container">
          <div className="row align-items-center">
            <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12"></div>
            <div className="col col-xl-6 col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget about-widget">
                <div className="logo widget-title">
                  <Link className="logo" onClick={ClickHandler} to="/">
                    M&C
                  </Link>
                </div>
                <p>
                  We can’t wait to see all of our beloved friends and relative s
                  at our wedding.
                </p>
              </div>
            </div>

            <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget wpo-service-link-widget">
                <div className="widget-title">
                  <h3>Contact </h3>
                </div>
                <div className="contact-ft">
                  <p>michael.peter.joyce@gmail.com | 0833583745</p>
                  <p>caoimhe.eduffy@gmail.com | 0860673678</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ft-shape-1">
        <Slide direction="left" duration="1000" triggerOnce="true">
          <img src={shape1} alt="" />
        </Slide>
      </div>
      <div className="ft-shape-2">
        <Slide direction="right" duration="1200" triggerOnce="true">
          <img src={shape2} alt="" />
        </Slide>
      </div>
    </footer>
  );
};

export default Footer;
