import React from "react";
import { Slide } from "react-awesome-reveal";
import SectionTitle from "../SectionTitle";
import sImg1 from "../../images/event/1.jpg";
import tankardstown from "../../images/event/tankardstown_1.jpeg";
import tankardstown_table from "../../images/event/tankardstown_table.png";
import tankardstown_flowers from "../../images/event/tankardstown_flowers.png";
import imperial from "../../images/event/imperial.png";
import sImg2 from "../../images/event/2.jpg";
import sImg3 from "../../images/event/3.jpg";
import sImg from "../../images/event/tankardstown_logo.jpeg";
import LocationMap from "./Modal";

const Events = [
  {
    Simg: tankardstown_flowers,
    title: "The Ceremony",
    location: 'Tankardstown',
    li1: "Friday, 12 Apr. 2024 1:00 PM ",
    li2: "Tankardstown, Rathkenny, Co. Meath, C15 D535",
    li3: "(041) 982 4621",
    animation: "1400",
  },
  {
    Simg: tankardstown_table,
    title: "The Reception",
    location: 'Tankardstown',
    li1: "Friday, 12 Apr. 2024",
    li2: "Tankardstown, Rathkenny, Co. Meath, C15 D535",
    li3: "(041) 982 4621",
    animation: "1200",
  },
  {
    Simg: imperial,
    title: "Day 2",
    location: 'Imperial',
    li1: "Saturday, 13 Apr. 2024",
    li2: "Imperial Hotel Dundalk, Park St, Dundalk, Co. Louth, A91 X92N",
    li3: "(042) 933 2241",
    animation: "1600",
  },
];

const EventSection = (props) => {
  return (
    <section className="wpo-event-section section-padding" id="event">
      <div className="container">
        <SectionTitle subTitle={"Our Wedding"} MainTitle={"When & Where"} />
        <div className="wpo-event-wrap">
          <div className="row">
            {Events.map((event, eitem) => (
              <div className="col col-lg-4 col-md-6 col-12" key={eitem}>
                <Slide
                  direction="up"
                  duration={event.animation}
                  triggerOnce="true"
                >
                  <div className="wpo-event-item">
                    <div className="wpo-event-img">
                      <div className="wpo-event-img-inner">
                        <img src={event.Simg} alt="" />
                      </div>
                    </div>
                    <div className="wpo-event-text">
                      <div className="title">
                        <h2>{event.title}</h2>
                      </div>
                      <ul>
                        <li>{event.li1}</li>
                        <li>{event.li2}</li>
                        <li>{event.li3}</li>
                        <li>
                          <LocationMap place={event.location} />
                        </li>
                      </ul>
                    </div>
                  </div>
                </Slide>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventSection;
