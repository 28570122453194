import React, { Fragment } from "react";

import { Button, Dialog, Grid } from "@mui/material";
import MuiDialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

const maps = {
  Tankardstown:
    "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2359.889076425257!2d-6.615533101743722!3d53.73805032393279!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNTPCsDQ0JzE3LjAiTiA2wrAzNic0OS4xIlc!5e0!3m2!1sen!2sie!4v1703018214241!5m2!1sen!2sie",
  Imperial:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2344.9801432294144!2d-6.404657422114019!3d54.00312802537784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4860cea786f065b1%3A0x8d74e34a960dce87!2sImperial%20Hotel!5e0!3m2!1sen!2sie!4v1703018288346!5m2!1sen!2sie",
};

const LocationMap = ({ maxWidth, button, buttonClass, place }) => {
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  const DialogTitle = (props) => {
    const { onClose } = props;
    return (
      <MuiDialogTitle>
        {onClose ? (
          <IconButton
            className="event-close-btn"
            aria-label="close"
            onClick={onClose}
          >
            <i className="ti-close"></i>
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };

  return (
    <Fragment>
      <Button className={`btn ${buttonClass}`} onClick={handleClickOpen}>
        {button}
        See Location
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        className="modalWrapper quickview-dialog"
        maxWidth={maxWidth}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {" "}
        </DialogTitle>
        <Grid className="modalBody modal-body">
          <Grid className="modalBody modal-body">
            <iframe src={maps[place]}></iframe>
          </Grid>
        </Grid>
      </Dialog>
    </Fragment>
  );
};
export default LocationMap;
